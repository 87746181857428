<template>
  <div class="djs_box">
    <!-- <div class="title_fh" @click="gobancks">
      <div class="fanhui">
        <img src="@/assets/images/fh.png" alt="" />
      </div>
      <div class="titel_nrons">代扣</div>
    </div> -->
    <div class="ridns">
      {{ djsData }}
    </div>
    <div class="sqidn">请稍等......</div>
  </div>
</template>

<script>
import { FaceIdentityResult, createContract } from "@/request/api";
export default {
  data() {
    return {
      djsData: "5",
      orderId: "",
      linssign: "",
    };
  },
  created() {},
  mounted() {
    const sign = this.$route.query.sign;
    let wedfe = this.$route.query.wedfe;
    let orderId = window.localStorage.getItem("withhold_orderId");
    console.log(orderId);
    this.orderId = orderId;
    // sign wedfe这两个值是订单创建的时候传来的 是直接签署合同的 不需要刷脸
    if (sign && wedfe) {
      this.linssign = sign;
      console.log(sign);
      this.orderId = sign.orderId;
      this.wedfeflag();
      this.goNewPage();
    } else {
      this.wedfe();
    }
  },
  methods: {
    wedfe() {
      let that = this;
      that.FaceIdentit();
      var clear = setInterval(function () {
        if (that.djsData == 1) {
          clearInterval(clear);
        } else {
          that.djsData--;
        }
      }, 1000);
    },
    wedfeflag() {
      let that = this;
      var clear = setInterval(function () {
        if (that.djsData == 1) {
          clearInterval(clear);
        } else {
          that.djsData--;
        }
      }, 1000);
    },

    // 人脸识别
    FaceIdentit() {
      FaceIdentityResult().then((res) => {
        if (res.code == "200") {
          if (res.data.status == 1) {
            this.goNewPage();
            this.$notify({
              type: "success",
              message: "人脸信息认证成功，即将创建合同",
            });
          } else {
            this.$notify({
              type: "warning",
              message: res.message,
            });
            // 测试 
            this.$router.push("/withholdList");
          }
        } else {
          this.$notify({
            type: "warning",
            message: res.message,
          });
          this.$router.push("/withholdList");
        }
        // alert(JSON.stringify(res));
      });
    },
    // 创建合同 并发送验证码
    goNewPage() {
      // alert(this.orderId);

      createContract({
        orderId: this.orderId,
      }).then((res) => {
        console.log(res);
        if (res.code == "200") {
          this.$notify({
            type: "success",
            message: res.message,
          });
          if (res.data.status == "1") {
            this.$router.push("/yamCode?orderId=" + this.orderId);
          } else {
            window.location.href = res.data.signUrl;
          }
          // this.$router.push({
          //   path: "/yamCode",
          //   query: { contract: res.data },
          // });
        } else {
          this.$notify({
            type: "warning",
            message: res.message,
          });
          // this.$router.push("/withholdList");
          // this.$router.push({
          //   path: "/home",
          // });
          this.$router.push("/withholdList");
        }
      });
    },
    // gobancks() {
    //   this.$router.push("/withholdbincj");
    // },
  },
};
</script>

<style lang="scss" scoped>
.djs_box {
  width: 100%;
  position: relative;
  .ridns {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8rem;
    height: 8rem;
    margin-top: 14rem;
    margin-left: -4rem;
    border-radius: 50%;
    background: #1989fa;
    color: #ffffff;
    font-size: 3rem;
    text-align: center;
    line-height: 8rem;
  }
  .sqidn {
    position: fixed;
    bottom: 10%;
    left: 40%;
    font-size: 1rem;
    color: #1989fa;
  }
}
</style>